import { createApp } from "vue";
import App from "./App.vue";

import Toaster from "@meforma/vue-toaster";

import "@/assets/global.css";
import router from "./router";
// import store from "./store";
import axios from "axios";
import VueAxios from "vue-axios";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";

createApp(App)
  .use(router)
  .use(Toaster)
  //   .use(store)
  .use(VueAxios, axios)
  .mount("#app");

// const app = createApp(App)
// app.use(store);
// app.use(VueToast)
// app.mount('#app')
