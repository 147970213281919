import { createRouter, createWebHistory } from "vue-router";
// import HomeComp from "../view/Inloopdouche.vue";
// import TrapliftenHome from "../view/Trapliften.vue";
// import Bedankt from "../view/InloopdoucheBedankt.vue";
// import TrapliftenBedankt from "../view/TrapliftenBedankt.vue";

const routes = [
  // {
  //   path: "/",
  //   name: "home",
  //   alias: "/inloopdouche",
  //   component: HomeComp,
  // },
  // {
  //   path: "/bedankt",
  //   name: "bedankt",
  //   component: Bedankt,
  // },
  // trapliften
  // {
  //   path: "/trapliften",
  //   name: "TrapliftenHome",
  //   alias: "/TrapliftenHome",
  //   component: TrapliftenHome,
  // },
  // {
  //   path: "/trapliften/bedankt",
  //   name: "trapliftenbedankt",
  //   component: TrapliftenBedankt,
  // },
];

const router = createRouter({
  mode: "history",
  history: createWebHistory(),
  base: process.env.BASE_URL,
  routes,
});

export default router;
