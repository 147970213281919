<template>
  <div class="app bg-img">
    <!-- <NavBar /> -->
    <div class="main-content">
      <router-view />
      <!-- <Footer /> -->
    </div>
  </div>
</template>

<script>
// import NavBar from "@/components/Navbar";
// import Footer from "@/components/Footer";

export default {
  name: "App",
  created() {
    document.title = "SnelleOfferte Inloopdouche";
  },
  components: {
    // NavBar,
    // Footer,
  },
};
</script>
<style>
.cursor-pointer {
  cursor: pointer !important;
}
</style>
